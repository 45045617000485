<div class="container">
	<div class="panel panel-primary"> 
		<div class="row">
			<div class="col col-sm-12">
				<div class="outer-mcq">
					<div class="panel-heading mb-1">
						<h3 class="panel-title">MCQ's</h3>
					</div>

					<div class="panel-body">
						<div class="inner-pannel" [hidden]="finished">
							<ul>
								<li *ngFor="let question of questions; let i = index" class="main-question-li" [hidden]="currQuestion !== i">
									<div class="question-list">
										<input type="hidden" class="input-value" [value]="i">
										<div class="d-flex"><span class="ques-heading">Q{{ i+ + 1 }}.</span> <b class="question-heading mb-3" [innerHtml]="question.question"></b></div>
										<div class="select-option">
											<div class="custom-form-data d-flex mb-4" *ngFor="let option of question.Options">
												<input [id]="'Option'+i+option.value" class="mr-1" type="radio" [value]="option.value" [name]="'answer['+i+']'" (click)="makeSelection(i, option.value)">
												<label [for]="'Option'+i+option.value">{{ option.label }}</label>
											</div>
										</div>
									</div>
								</li>
							</ul>

							<div class="buttons-pannel">
								<button class="btn btn-default mr-1" (click)="nextQuestion()" [disabled]="currQuestion == questions.length - 1">Next</button>
								<button class="btn btn-default" [hidden]="currQuestion != questions.length - 1" (click)="finishQuiz()">Finish</button>
							</div>
						</div>
						<div class="finished-pannel" [hidden]="!finished">
							<div class="thank-you-outer" [hidden]="result">
								<p class="thank-you">Thank you!</p>
								<div class="result-div">
									<button class="btn btn-default mr-1" (click)="checkResult()">Check Result</button>
								</div>
							</div>

							<div class="result-outer" [hidden]="!result">
								<p class="thank-you">Result.</p>
								<div class="score-card">
									{{ score }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>