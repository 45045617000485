import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { McqComponent } from './mcq/mcq.component';

const routes: Routes = [
	{
	    path: '',
	    component: McqComponent
	},
];

@NgModule({
  	imports: [RouterModule.forRoot(routes)],
  	exports: [RouterModule]
})


export class AppRoutingModule {}